import type { NextPage } from 'next';
import React, { useEffect, useState } from 'react';
import { CardInput } from '../src/components/CardInput/CardInput';
import { Balloon } from '../src/components/Header/Balloon';
import { Footer } from '../src/components/Footer/Footer';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { appStateRecoil, getLogonName } from '../src/state/appState';
import { usePushToCard } from '../src/hooks/usePushToCard';
import { SideMenu } from '../src/components/Menu/SideMenu';

const Index: NextPage = () => {
    const setAppState = useSetRecoilState(appStateRecoil);

    const logonName = useRecoilValue(getLogonName);

    const { loadCard } = usePushToCard();

    const [pushed, setPushed] = useState<null | boolean>(null);

    // Shows the Start Page only when there's no logonName in the localStorage; otherwise push to the user
    useEffect(() => {
        if (!logonName && !pushed) {
            const userFromStorage = localStorage.getItem('u');

            if (userFromStorage && userFromStorage.length > 3) {
                setAppState((currVal) => {
                    return { ...currVal, logonName: userFromStorage };
                });

                if (userFromStorage) loadCard(userFromStorage);
                setPushed(true);
            } else {
                setPushed(false);
            }
        }
    }, [loadCard, logonName, pushed, setAppState]);

    return (
        <>
            {(pushed === false || logonName) && (
                <div className="relative mx-auto mt-4 flex h-max w-full max-w-md flex-col items-center justify-center gap-4 px-8">
                    <>
                        <div className="absolute left-4 top-4 z-30">
                            {/*<ShareModal />*/}
                            <SideMenu />
                        </div>
                        <Balloon />
                        <div className="relative flex h-[90vH] flex-col items-center justify-center gap-1">
                            <CardInput />
                        </div>
                        <Footer />
                    </>
                </div>
            )}
        </>
    );
};
export default Index;
