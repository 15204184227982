import { useCallback } from 'react';
import { useRouter } from 'next/router';

export const usePushToCard = () => {
    const { push } = useRouter();

    const loadCard = useCallback(
        (code: string) => {
            if (code.length > 5) {
                void push(`/me/${code}`);
            }
        },
        [push]
    );

    return { loadCard };
};
