import React, { useState } from 'react';
import { Button } from '../Buttons/Button';
import { usePushToCard } from '../../hooks/usePushToCard';
import { useLocalisation } from '../Localisation/hooks/useLocalisation';

export interface ICardInput {}

export const CardInput: React.FC<ICardInput> = () => {
    const [code, setCode] = useState<string>('');

    const { loadCard } = usePushToCard();

    const { translations } = useLocalisation();

    return (
        <>
            <section className="relative mb-8 flex flex-col gap-1">
                <h1 className="text-4xl font-bold text-primary">RE/MAX</h1>
                <h1 className="text-2xl font-bold text-secondary">Digital Businesscard</h1>
            </section>
            <section className="relative flex w-full flex-col gap-1">
                <span className="font-medium text-lg text-neutral-400">
                    {translations.codeEntryInputLabel}
                </span>
                <input
                    type="text"
                    className="w-full border-2 border-primary-light bg-white px-4 py-4 font-medium text-xl text-primary"
                    autoCapitalize="none"
                    value={code}
                    onChange={(e) => {
                        setCode(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        if (e.code.toLowerCase() === 'enter') {
                            loadCard(code);
                        }
                    }}
                />
            </section>
            <section className="relative mt-4 flex w-full flex-row items-center justify-center gap-1">
                <Button
                    color={code.length > 0 ? 'primary' : 'default'}
                    onClick={() => {
                        loadCard(code);
                    }}
                >
                    {translations.codeEntryButtonLabel}
                </Button>
            </section>
        </>
    );
};
